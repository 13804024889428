import React, { useState } from 'react';
import styled from 'styled-components'
import { colors } from '../../utils/colors';
import { StaticImage } from "gatsby-plugin-image"
import './hamburger.css'

const StyledWrapper = styled.nav`
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    z-index: 100;

    @media (min-width: 960px){
        border-bottom: 2px solid ${colors.mainRed};
        width: 100%;
    }
`;

const StyledBrand = styled.a`
    display: flex;
    align-items: center;
    color: ${colors.lightWhite};
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
`;

const NameBrand = styled.p`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
`;

const NavWrapper = styled.div`

    @media (min-width: 960px) {
        margin: 0 auto;
        width: 80%;
        max-width: 1200px;
        display: flex;
        justify-content: space-between;
    }

`;

const LogoBox = styled.div`
    display: flex;
    align-items: center;
    padding: 1em;

    @media (min-width: 960px){
        padding: 0.2em 1em;
    }
`;


const StyledLink = styled.li`
    position: relative;
    display: inline-block;
    margin: 0.5em;
    padding: 0.5em;
    font-size: ${(props) => props.fontmobile ? '1.5rem' : '1.3vw'};
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    &::before, &::after {
        content: '';
        position: absolute;
        width: 0;
        bottom: 0;
        border-bottom: 2px solid ${colors.mainRed};
        transition: .3s;
    }
    &::before {
        right: 50%;
    }
    &::after {
        left: 50%;
    }
    &:hover::before, &:hover::after {
        width: 50%;
    }

    a {
        text-decoration: none;
        color: ${colors.lightWhite};
    }
`;

const StyledNavDesktop = styled.ul`
    display: none;

    @media (min-width: 960px) {
        display: flex;
        align-items: center;
        height: 100%;
    }
`;

const HamburgerButton = styled.button`

    &.isActive .hamburgerInner,
    &.isActive .hamburgerInner::before,
    &.isActive .hamburgerInner::after {
       
      z-index: 500;
    }

    @media (min-width: 960px) {
        display: none;
    }
`;

const InnerLink = styled.a``

const StyledNavMobile = styled.ul`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: ${colors.lightWhite};
    transform: translateX(-102%);
    transition: transform .3s;
    z-index: 0;
    padding: 1em;
    &.active{
        transform: translateX(0);
    }

    @media (min-width: 960px) {
        display: none;
    }
`;

const styledLinks = [
    {
        link: '/#onas',
        content: 'O nas'
    },
    {
        link: '/#uslugi',
        content: 'Usługi'
    },
    {
        link: '/#dlaczegomy',
        content: 'Dlaczego My?'
    },
    {
        link: '/#opinie',
        content: 'Opinie'
    },
    {
        link: '/#kontakt',
        content: 'Kontakt'
    },
];

const width = 30;
const height = (width * 16) / 9

function Nav() {

    const [navbar, setNavbar] = useState(false);
    const [isActive, setActive] = useState(false);
    const handleNav = () => {
        setActive(!isActive);
    }
    const isBrowser = typeof window !== "undefined";

    const changeBgc = () => {
        if(window.scrollY >= 100){
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    if (isBrowser) {
        window.addEventListener('scroll', changeBgc);
      }
    

    return (
        <StyledWrapper className={`${navbar ? `scrolled` : ""}`}>
            <NavWrapper>
                <StyledBrand href="/#home">
                    <LogoBox>
                        <StaticImage
                            src="../../images/lapa.svg"
                        quality={95}
                            height={height}
                            formats={["AUTO", "WEBP"]}
                            alt="Logotyp WebyBear"
                        />
                        <NameBrand className={`${navbar ? `dark` : ""}`}>WebyBear</NameBrand>
                    </LogoBox>
                </StyledBrand>
                <HamburgerButton  className={`hamburger hamburgerSpin ${isActive ? `isActive` : ""}`} type="button" onClick={handleNav}>
                    <span className='hamburgerBox'>
                        <span className={`${navbar ? `scrolledHamburgerInner hamburgerInner` : "hamburgerInner"}`}></span>
                    </span>
                </HamburgerButton>
                <StyledNavDesktop>
                    {styledLinks.map((link, idx) => (
                            <StyledLink className={`${navbar ? `scrolledLink scrolledMenuLink` : "link menuLink"}`}  key={idx}>
                                <a href={link.link} className={`${navbar ? `scrolledLink scrolledMenuLink` : "link menuLink"}`} >
                                    {link.content}
                                </a>
                            </StyledLink>
                        ))}
                </StyledNavDesktop>
                <StyledNavMobile className={`${isActive ? `active` : ""}`}>
                {styledLinks.map((link, idx) => (
                            <StyledLink as={InnerLink} fontmobile className={`scrolledLink scrolledMenuLink`} to={link.link} onClick={handleNav} key={idx}>
                                {link.content}
                            </StyledLink>
                        ))}
                </StyledNavMobile>
            </NavWrapper>
        </StyledWrapper>
    )
}

export default Nav