import React from 'react';
import styled from 'styled-components'
import ServiceCard from '../ServicesCard/ServicesCard';
import Card1Img from '../../images/networks-1987215_640.jpg'
import Card2Img from '../../images/code-820275_640.jpg'
import Card3Img from '../../images/digital-marketing-1725340_640.jpg'

const CardsRow = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 4em 0;
    width: 80%;
    
    @media (min-width: 768px){
        flex-direction: row;
    }
`;

const Cards = [
    {
        cardImg: Card1Img,
        cardTitle: 'Tworzenie stron internetowych',
        cardTitle2: 'Tworzenie stron internetowych',
        cardContent: 'Tworzymy profesjonalne i nowoczesne strony internetowe, dzięki którym trafisz do nowych klientów i wyróżnisz się na tle konkurencji. Pozwól nam, stworzyć witrynę Twojej firmy. Zajmujemy się kompleksowym wdrażaniem sklepów internetowych, które przyniosą Ci nawet kilkukrotnie większą sprzedaż. Nie boimy się odważnych zleceń – sklepy od jednego do tysiąca produktów.',
    },
    {
        cardImg: Card2Img,
        cardTitle: 'Optymalizacja działających serwisów',
        cardTitle2: 'Optymalizacja serwisów',
        cardContent: 'Twoja strona www będzie czytelna nie tylko dla ludzi ale także dla wyszukiwarki Google. Optymalizacja składa się z wielu elementów, nie jest to jednorazowe działanie, oraz 10 minut pracy programisty. Jeżeli Twoja strona nie przynosi zysków, użytkownicy szybko opuszczają Twoją stronę, lub po prostu nie masz organicznych wejść, to prawdopodobnie Twoja strona internetowa jest słabo zoptymalizowana.',
    },
    {
        cardImg: Card3Img,
        cardTitle: 'Wsparcie marketingowe',
        cardTitle2: 'Wsparcie marketingowe',
        cardContent: 'Wspieramy naszych klientów w realizacji celów biznesowych online, pomagając wykorzystać potencjał drzemiący w Internecie. Strategia marketingowa to jeden z fundamentów potrzebnych do rozpoczęcia biznesu. Nie masz czasu na kreację? Burza mózgów to nie Twój świat? Skorzystaj z usług profesjonalistów. WebyBear przygotuje strategię dla Twojej firmy oraz narzędzia do jej wdrożenia.',
    },
];


const ServicesCardsRow = () => (
    <CardsRow>
        {Cards.map((card, idx) => (
            ServiceCard(card.cardImg, card.cardTitle, card.cardTitle2, card.cardContent, idx)
        ))}
    </CardsRow>
)

export default ServicesCardsRow