import React from 'react';
import styled from 'styled-components'
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import img from '../../images/code-820275_640.jpg'
import { colors } from '../../utils/colors';
import Square from "../../images/square.svg"
import Traingle from "../../images/triangle.svg"
import Ex from "../../images/x.svg"

const StyledWrapper = styled.section`
position: relative;
    background-image: url(${img});
    background-position: center;
    background-size: cover;
    z-index: 0;
    padding: 6em 2em;
`;

const BgShade = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.mainBlue};
    z-index: -1;
    opacity: .85;
`;

const ContentWrapper = styled.div`
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
`;

const ContactContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 960px) {
        flex-direction: row;
    }
`;

const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: ${colors.lightWhite};
    margin: 2em 0;
    text-align: center;
    h3 {
        font-size: 1.5rem;
        padding-bottom: 1em;
    }
    p {
        font-size: 1.2rem;
        padding: 1rem 0;
    }
    @media (min-width: 960px) {
        width: 50%;
    }
`;

const FormContainer = styled.div`
    width: 100%;
    @media (min-width: 960px) {
        width: 50%;
    }
`;

const ColorLink = styled.a`
    color: white;
`;

// const StyledForm = styled.form`
//     padding-top: 2em;
//     height: 80%;
//     width: 100%;
// `;

// const FormBox = styled.div`
//     display: flex;
//     flex-direction: column;
// `;

// const FormLabel = styled.label`
//     font-size: 1.1rem;
//     color: #fff;
//     text-transform: uppercase;
//     line-height: 1.5;
// `;

// const FormInput = styled.input`
// background: #fff;
// border: none;
// border: 1px solid ${colors.mainRed};
// border-radius: 8px;
// font-size: 1.1rem;
// line-height: 1.2;
// padding: 5px;
// `;

// const FormTextArea = styled.textarea`
// width: 100%;
// min-width:100%;
// max-width:100%;
// min-height: 100px;
// max-height: 200px;
// background: #fff;
// border: none;
// border: 1px solid ${colors.mainRed};
// font-size: 1.1rem;
// line-height: 1;
// padding: 5px;
// border-radius: 8px;
// `;

// const FormButton = styled.button`
//     text-decoration: none;
//     color: ${colors.lightWhite};
//     border: none;
//     border-radius: 5px;
//     background-color: ${colors.mainRed};
//     font-size: .8rem;
//     text-transform: uppercase;
//     font-weight: bold;
//     margin: 2em 0;
//     padding: 1em 2em;
//     cursor: pointer;
//     transition: color .3s;
//     position: relative;
//     transition: background-color .3s;
//     overflow: hidden;
//     z-index: 0;

//     &::before {
//         display: block;
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         transform: scaleX(0);
//         transform-origin: right;
//         transition: transform .3s;
//         z-index: -1;
//         background-color: ${colors.mainYellow};
//     }
//     &:hover {
//         color: ${colors.mainBlue};
//     }
//     &:hover::before {
//         transform: scaleX(1);
//         transform-origin: left;
//     }
// `;

const sectionShapes = [
    {
        class: 'shape shape--one shape--desktop',
        src: Square
    },
    {
        class: 'shape shape--two shape--desktop',
        src: Ex
    },
    {
        class: 'shape shape--three shape--desktop',
        src: Ex
    },
    {
        class: 'shape shape--four',
        src: Square
    },
    {
        class: 'shape shape--five',
        src: Square
    },
    {
        class: 'shape shape--six',
        src: Traingle
    },
    {
        class: 'shape shape--seven',
        src: Traingle
    },
    {
        class: 'shape shape--eight',
        src: Traingle
    },
];

const Contact = () => (
    <StyledWrapper id="kontakt">
        <BgShade/>
        {SectionTitle('Kontakt', 'blue')}
        <ContentWrapper>
            <ContactContainer>
                <ContactInfo>
                        <h3>Nasz adres:</h3>
                        <br/>
                        <p>ul. Budziszyńska 120</p>
                        <p>42-452 Wrocław</p>
                        <ColorLink href="mailto:office@webybear.com">
                            office@webybear.com
                        </ColorLink>
                </ContactInfo>
                <FormContainer>
                    {/* <StyledForm method="post" action="mail.php">
                        <FormBox>
                            <FormLabel htmlFor="fullname">Imię</FormLabel>
                            <FormInput type="text" id="fullname" name="fullname"/>
                        </FormBox>
                        <FormBox>   
                            <FormLabel htmlFor="email">e-mail</FormLabel>
                            <FormInput type="e-mail" id="email" name="email"></FormInput>
                        </FormBox>
                        <FormBox>
                            <FormLabel htmlFor="msg">Wiadomość</FormLabel>
                            <FormTextArea id="msg" name="msg"></FormTextArea>
                        </FormBox>
                        <FormButton type="submit">Wyślij</FormButton>
                    </StyledForm> */}
                </FormContainer>
            </ContactContainer>
        </ContentWrapper>
        {sectionShapes.map((shape, idx) => (
            <img src={shape.src} className={shape.class} alt="shape" key={idx}/>
        ))}
    </StyledWrapper>
)

export default Contact