import React from 'react';
import styled from 'styled-components'
import aboutImg from '../../images/startup-849804_1280.jpg'
import SectionTitle from '../SectionTitle/SectionTitle';


const StyledWrapper = styled.div`
    order: 1;
    width: 300px;
    height: 350px;
    background-image: linear-gradient(to top right, rgba(10, 19, 133, .95),  rgba(121, 169, 223,.7)), url(${aboutImg});
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 2px 2px 5px rgb(0,0,0);
    margin-bottom: 5em;  
    
    @media (min-width: 992px) {
        width: 30vw;
        height: 450px;
        margin-bottom: 0;  
    }
`;

const AboutusPic = () => (
    <StyledWrapper>
        {SectionTitle('Kim jesteśmy ?', 'blue')}
    </StyledWrapper>
)

export default AboutusPic