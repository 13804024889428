import * as React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import Footer from '../components/Footer/Footer'
import Header from "../modules/Header/Header"
import { colors } from "../utils/colors"

const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
    font-size: 16px;
  }
  body {
    padding: 0;
    margin: 0;
  }
  
  *,*::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .custom-shape-divider-bottom-1619022311, .custom-shape-divider-bottom-1619022312, .custom-shape-divider-bottom-16190223113 {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);

    svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 30px;
        transform: rotateY(180deg);
    }

    & ,.shape-fill {
        fill: ${colors.lightWhite};
    }
}
.custom-shape-divider-bottom-1619022312 {

    & ,.shape-fill-2 {
        fill: ${colors.mainGrey};
    }
}
.custom-shape-divider-bottom-16190223113 {
    & ,.shape-fill-3 {
        fill: ${colors.mainBlue};
    }
}

.shape {
  position: absolute;
  opacity: 0.4;
  z-index: -1;

  &--desktop {
      display: none;
  }

  &--one {
      top: 14%;
      left: 5%;
      width: 5%;
      animation: rotate-right 20s infinite linear;
  }

  &--two {
      bottom: 40%;
      left: 35%;
      width: 10%;
      animation: rotate-right 20s infinite linear;
  }

  &--three {
      top: 26%;
      left: 56%;
      width: 4%;
      animation: rotate-left 30s infinite linear;
  }
  &--four {
      top: 55%;
      left: 50%;
      width: 7%;
      animation: rotate-left 30s infinite linear;
  }
  &--five {
      top: 10%;
      left: 80%;
      width: 10%;
      animation: rotate-left 30s infinite linear;
  }
  &--six {
      bottom: 15%;
      left:75%;
      width: 10%;
      animation: rotate-right 40s infinite linear;
  }
  &--seven {
      bottom: 15%;
      left: 10%;
      width: 15%;
      animation: rotate-right 30s infinite linear;
  }
  &--eight {
      top: 10%;
      left: 45%;
      width: 10%;
      animation: rotate-left 30s infinite linear;
  }
}

  @media screen and (min-width: 320px) {
    html {
      font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    }
  }

  @media screen and (min-width: 1000px) {
    html {
      font-size: 22px;
    }
  }

  @media screen and (min-width: 768px) {
    .shape--desktop {
      display: block;
    }
  }

  @keyframes rotate-right {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate-left {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(-360deg);
    }
}
`;


const Layout = ({ children }) => {

    return (
      <>
        <GlobalStyle/>
        <Header/>
        <main>{children}</main>
        <Footer/>
      </>
    )
  }
  
  Layout.propTypes = {
    children: PropTypes.node.isRequired,
  }
  
  export default Layout