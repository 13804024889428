import React from 'react';
import styled from 'styled-components'
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { colors } from '../../utils/colors';
import img from '../../images/code-4333398_1920.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faWpforms } from '@fortawesome/free-brands-svg-icons'
// import { faClipboardList, faCalendarAlt, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import Square from "../../images/square.svg"
import Ex from "../../images/x.svg"

const StyledWrapper = styled.section`
    position: relative;
    background-image: url(${img});
    background-position: center;
    background-size: cover;
    z-index: 0;
    padding: 6em 2em;
`;

const BgShade = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.mainBlue};
    z-index: -1;
    opacity: .85;
`;

const StyledText = styled.p`
    font-size: 1.2rem;
    text-align: center;
    margin: 2em 0;  
    color: #fff;
`;

const ContentWrapper = styled.div`
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
`;

const StepsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    @media (min-width: 768px){
        align-items: center;
    }
    @media (min-width: 1200px){
        flex-direction: row;
    }
`;

const StyledStep = styled.div`
    position: relative;
    width: 110%;
    height: 120px;
    box-shadow: 5px 5px 10px rgba(0,0,0,.7);
    border-radius: 70px;
    background: ${colors.lightWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;

    p {
        font-size: .7rem;
        width: 75%;
        padding: 10px;
        color: #000;
    }


    @media (min-width: 576px) {
        p {
            font-size: .8rem;  
        }
    }
    @media (min-width: 768px) {
        width: 90%;
    }
    @media (min-width: 960px) {
        width: 80%;

        p {
           width: 70%;
        }
    }
    @media (min-width: 1200px) {
        width: 45%;
        p {
            font-size: .7rem;
         }
    }
`;

// const StepIcon = styled.div`
//     position: absolute;
//     width: 20%; 
//     height: 60%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: linear-gradient(to top right, rgba(10, 19, 133, .95),  rgba(121, 169, 223,.7)) padding-box, linear-gradient(to top right, rgba(10, 19, 133, .95),  rgba(121, 169, 223,.7)) border-box;
//     border-radius: 0 50px 0 50px;
//     left: 10px;
//     top: -15%;
//     font-size: 1rem;
//     color: #fff;

//     @media (min-width: 476px) {
//         width: 20%;
//         height: 80%;
//         font-size: 2rem;
//     }
//     @media (min-width: 768px) {
//         font-size: 3rem;
//     }
//     @media (min-width: 960px) {
//         font-size: 5rem;
//         height: 130%;
//         width: 25%;
//     }
// `;


const cooperationClaim = 'Zobacz, jak krok po kroku wygląda współpraca z WebyBear. Wystarczy, że wyślesz nam dane kontaktowe - wykorzystaj do tego formularz zawarty na stronie. W ten sposób robisz pierwszy krok do współpracy z nami. Dołącz do grona naszych klientów!';

const cooperationSteps = [
    {
        // icon: faWpforms,
        text: 'Wystarczy wypełnić formularz kontaktowy, by od razu otrzymać informację zwrotną.'
    },
    {
        // icon: faClipboardList,
        text: 'Twoje oczekiwania są już ustalone? Doskonale - jak najszybciej dostarczymy Ci proponowany zakres prac. Otrzymasz również kosztorys.'
    },
    {
        // icon: faCalendarAlt,
        text: 'Czas na rozmowę. Wspólnie ustalimy pierwsze kroki. Na zakończenie otzymasz e-mail z podsumowaniem. Spójrz na zegarek - zawsze wysyłamy go w ciągu godziny od rozmowy'
    },
    {
        // icon: faCheckSquare,
        text: 'To już niemal koniec - czas działać! Ustalamy ostateczny zakres współpracy i startujemy z aktywnością marketingową Twojego biznesu.'
    },
];

const Cooperation = () => (
    <StyledWrapper>
        <BgShade/>
        {SectionTitle('Współpraca krok po kroku', 'blue')}
        <ContentWrapper>
            <StyledText>
                {cooperationClaim}
            </StyledText>

            <StepsContainer>
                {cooperationSteps.map((step, idx) => (
                    <StyledStep key={idx}>
                        {/* <StepIcon> */}
                            {/* <FontAwesomeIcon icon={step.icon} className="ico"/> */}
                        {/* </StepIcon> */}
                        <p>{step.text}</p>
                    </StyledStep>
                ))}

            </StepsContainer>
        </ContentWrapper>
        <div className="custom-shape-divider-bottom-1619022311">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" className="shape-fill"></path>
                </svg>
            </div>
            <img src={Ex} alt="" className="shape shape--one"/>
        <img src={Square} alt="" className="shape shape--five"/>
    </StyledWrapper>
)

export default Cooperation