import React from 'react';
import styled from 'styled-components'
import { colors } from '../../utils/colors';


const StyledFooter = styled.footer`
    background-color: ${colors.mainGrey};
    text-align: center;
    p {
        font-size: 1rem;
    }
`;

const Footer = () => (
    <StyledFooter>
        <p>
            © {new Date().getFullYear()} WebyBear
        </p>

    </StyledFooter>
)

export default Footer