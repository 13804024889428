import * as React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import headerImg from '../../images/11733-laptop-keyboards-Hewlett_Packard.jpg'
import { colors } from "../../utils/colors"
import Nav from "../Navigation/Navigation"
import Square from "../../images/square.svg"
import Traingle from "../../images/triangle.svg"
import Ex from "../../images/x.svg"

const StyledHeader = styled.header`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url(${headerImg});
    background-size: cover;
    background-position: center;
    overflow: hidden;
    z-index: 1;
`;

const Shadow = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.mainBlue};
    z-index: -1;
    opacity: .85;
`;

const HeaderCta = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    text-align: center;
    width: 80%;
`;

const HeaderTitle = styled.div`
    line-height: 1.5;
    color: ${colors.lightWhite};
    text-shadow: 2px 2px 5px #000;
`;
const HeaderText = styled.div`
    margin: 2em 0;
    line-height: 1.5;
    color: ${colors.lightWhite};
    text-shadow: 2px 2px 5px #000;
    @media (min-width: 576px){
        margin: 1em 0;
    }
`;

const HeaderBtn = styled.a`
    text-decoration: none;
    color: ${colors.lightWhite};
    border: none;
    border-radius: 5px;
    background-color: ${colors.mainRed};
    text-transform: uppercase;
    font-weight: bold;
    padding: 1em 2em;
    cursor: pointer;
    transition: color .3s;
    position: relative;
    transition: background-color .3s;
    overflow: hidden;
    z-index: 0;

    &::before {
        background-color: ${colors.mainYellow};
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s;
        z-index: -1;
    }
    &:hover {
        color: ${colors.mainBlue}
    }
    &:hover::before {
        transform: scaleX(1);
        transform-origin: left;
    }
`;

const sectionShapes = [
    {
        class: 'shape shape--one shape--desktop',
        src: Square
    },
    {
        class: 'shape shape--two shape--desktop',
        src: Ex
    },
    {
        class: 'shape shape--three shape--desktop',
        src: Ex
    },
    {
        class: 'shape shape--four',
        src: Square
    },
    {
        class: 'shape shape--five',
        src: Square
    },
    {
        class: 'shape shape--six',
        src: Traingle
    },
    {
        class: 'shape shape--seven',
        src: Traingle
    },
    {
        class: 'shape shape--eight',
        src: Traingle
    },
];

const Header = () => (
  <StyledHeader id="home">
    <Shadow/>
    <Nav/>
    <HeaderCta>
        <HeaderTitle>
            <h1>Z WebyBear Twój biznes będzie skuteczniejszy!</h1>
        </HeaderTitle>
        <HeaderText>
            <h2>Sprawdź, co możemy zrobić dla Ciebie</h2>
        </HeaderText>
        <HeaderBtn href="#kontakt">
        Skontaktuj się!
        </HeaderBtn>
    </HeaderCta>
    <div className="custom-shape-divider-bottom-1619022311">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
              </svg>
    </div>
    {sectionShapes.map((shape, idx) => (
            <img src={shape.src} className={shape.class} alt="shape" key={idx}/>
        ))}
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `WebyBear - Strony Internetowe`,
}

export default Header