import React from 'react';
import styled from 'styled-components'
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import ServicesCardsRow from '../../components/ServicesCardsRow/ServicesCardsRow';
import { colors } from '../../utils/colors';
import Square from "../../images/square.svg"
import Traingle from "../../images/triangle.svg"
const StyledWrapper = styled.section`
    padding: 5em 2em;
    position: relative;
    background-color: ${colors.mainGrey};
    z-index: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const OurServices = () => (
    <StyledWrapper id="uslugi">
        {SectionTitle('Co robimy ?', 'grey')}
        <ServicesCardsRow/>
        <div className="custom-shape-divider-bottom-1619022311">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" className="shape-fill"></path>
            </svg>
        </div>
        <img src={Square} alt="" className="shape shape--one"/>
        <img src={Traingle} alt="" className="shape shape--five"/>
    </StyledWrapper>
)

export default OurServices