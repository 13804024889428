import * as React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import AboutUs from "../modules/Aboutus/Aboutus"
import Contact from "../modules/Contact/Contact";
import Cooperation from "../modules/Cooperation/Cooperation";
import OurServices from "../modules/OurServices/OurServices"
// import Technologies from "../modules/Technologies/Technologies";
import Testimonials from "../modules/Testimonials/Testimonials";
// import UspSection from "../modules/UspSection/UspSection";
import WhyUs from "../modules/WhyUs/WhyUs";


const IndexPage = () => {
  return (
    <>

    <Layout>
        <Seo title="WebyBear Strony Internetowe" lang="pl" description="WebyBear Strony Internetowe zapewnia kompleksową pomoc w tworzeniu i optymalizacji od prostych stron po rozbudowane serwisy internetowe"/>

        <AboutUs/>
        <OurServices/>
        <WhyUs/>
        {/* <Technologies/> */}
        {/* <UspSection/> */}
        <Cooperation/>
        <Testimonials/>
        <Contact/>
    </Layout>
    </>
  )
}

export default IndexPage
