import React from 'react';
import styled from 'styled-components'
import { colors } from '../../utils/colors';

const Title = styled.h2`
    position: relative;
    margin-bottom: 1em;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: ${colors.mainBlue};
    letter-spacing: 2px;
    text-shadow: 2px 2px 5px rgb(0,0,0);
    &.blue {
        color: ${colors.lightWhite};
    }
    &.grey {
        text-shadow: 1px 1px 1px rgba(82, 82, 82, .4);
    }
`;  

const SectionTitle = (title, blue) => (
    <Title className={blue}>
        {title}
    </Title>
)

export default SectionTitle