import React from 'react';
import styled from 'styled-components'

const StyledWrapper = styled.div`
    width: 100%;
    order: 2;
    line-height: 1.6;
    text-align: center;
    
    @media (min-width: 992px) {
        width: 50%;
    }
`;

const AboutContent = [

    {
        text: 'WebyBear to grupa strategów, developerów oraz pasjonatów marketingu cyfrowego. Serwisy, strony internetowe, e-commerce czy digital marketing - żadna z tych sfer nie ma dla nas tajemnic.'
    },
    {
        text: 'Przez lata pracowaliśmy w firmach technologicznych i agencjach, by nasze ścieżki zawodowe przecięły się w jednym punkcie. Tak powstało WebyBear. Dziś wykorzystujemy ponad 15 lat naszych doświadczeń.',
    },
    {
        text: 'Wspomagamy rozwój zarówno dużych firm jak i innowacyjnych start-upów. Mała firma, średnie przedsiębiorstwo, silny podmiot na rynku - zapewniamy wsparcie każdemu z nich.'
    },
];

const AboutusText = () => (
    <StyledWrapper>
        {AboutContent.map((text, idx) => (
            <p key={idx}>{text.text}</p>
        ))}
    </StyledWrapper>
)

export default AboutusText