import React from 'react';
import styled from 'styled-components'
import { colors } from '../../utils/colors';

const StyledCard = styled.div`
    position: relative;
    margin-bottom: 1.5em;
    width: 100%;
    height: 300px;
    color: ${colors.lightWhite};
    text-align: center;
    perspective: 1000px;
    
    &:hover {
        .img {
            transform: rotateY(-180deg);
            opacity: 0;
        }
        .info {
            transform: rotateY(0);
            opacity: 1;
        }
    }

    @media (min-width: 476px){
        width: 80%;
        height: 350px;
        margin: 0.5em;
    }
    @media (min-width: 768px){
        width: 60%;
        height: 400px;
    }
    @media (min-width: 992px){
        width: 40%;

    }
    @media (min-width: 1200px){
        width: 27%;
        margin: 1em;
    }

`;

const CardImg = styled.div`
    padding: 2em;
    height: 100%;
    border-radius: 8px;
    background-size: cover;
    transition: transform 1s, opacity 1s;
    background-image: linear-gradient(to top right, rgba(10, 19, 133, .95),  rgba(121, 169, 223,.7)), url(${({img}) => img});
    box-shadow: 2px 2px 5px rgb(0,0,0);
`;

const CardImgTitle = styled.h3`
    text-shadow: 2px 2px 5px rgb(0,0,0);
    text-align: center;
`;

const CardImgInfo = styled.p`
    position: absolute;
    bottom: 30px;
    right: 30px;
    text-shadow: 2px 2px 5px rgb(0,0,0);
`;

const CardInfo = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.mainBlue};
    border-radius: 8px;
    opacity: 0;
    transform: rotateY(180deg);
    transition: transform 1s, opacity 1s;
    box-shadow: 2px 2px 5px rgb(0,0,0);
`;

const CardInfoTitle = styled.h3`
    margin-top: 1em;
    display: none;

    @media (min-width: 478px){
        display: block;
    }
`;

const CardInfoText = styled.p`
    margin-top: 1em;
    width: 80%;    
    font-size: .75rem;
    @media (min-width: 960px){
        margin-top: 0;
    }
    @media (min-width: 992px){
        font-size: .65rem;
    }
    @media (min-width: 1200px){
        font-size: .6rem;
    }
    @media (min-width: 1400px){
        font-size: .65rem;
    }
`;

const CardInfoBtn = styled.a`
    position: absolute;
    width: 80%;
    font-size: .75rem;
    margin: 0 auto;
    bottom: 1em;
    padding: 1em;
    border-radius: 8px;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    color: ${colors.lightWhite};
    background-color: ${colors.mainRed};
    text-decoration: none;
    cursor: pointer;
    transition: color .3s;

    &:hover {
        color: ${colors.mainBlue};
    }

    &::before {
        background-color: ${colors.mainYellow};
    }

    &.btn-animation {
        position: relative;
        transition: background-color .3s;
        overflow: hidden;
        z-index: 0;
    
        &::before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform .3s;
            z-index: -1;
        }
        &:hover::before {
            transform: scaleX(1);
            transform-origin: left;
        }
    }
`;


const ServiceCard = (img, title, infoTitle, text, idx) => (
    <StyledCard onclick={void(0)} key={idx}>
        <CardImg img={img} className='img'>
            <CardImgTitle>
                {title}
            </CardImgTitle>
            <CardImgInfo>
                Więcej informacji
            </CardImgInfo>
        </CardImg>
        <CardInfo className='info'>
            <CardInfoTitle>{infoTitle}</CardInfoTitle>
            <CardInfoText>{text}</CardInfoText>
            <CardInfoBtn href="#kontakt" className={'btn-animation'}>Skontaktuj się</CardInfoBtn>
        </CardInfo>
    </StyledCard>
)

export default ServiceCard