import React from 'react';
import styled from 'styled-components'
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { colors } from '../../utils/colors';
import Square from "../../images/square.svg"
import Traingle from "../../images/triangle.svg"

const StyledWrapper = styled.section`
    position: relative;
    background-color: ${colors.lightWhite};
    z-index: 0;
    padding: 6em 2em;
`;

const ContentWrapper = styled.div`
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
`;

const WhyInfo = styled.p`
    text-align: center;
    margin: 2em 0;
`;

const ReasonSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    @media (min-width: 1200px){
        flex-direction: row;
    }
`;


const StyledReason = styled.div`
    position: relative;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to top right, rgba(10, 19, 133, .95),  rgba(121, 169, 223,.7));
    background-color: #1b3b77;
    display: flex;
    align-items: center;
    margin-bottom: 3em;
    box-shadow: 3px 3px 3px rgba(82, 82, 82, .4);

    &.one{
        border-radius: 50px 50px 0 50px;
        justify-content: flex-end;
    }
    &.two{
        border-radius: 50px 50px 50px 0;
        justify-content: flex-start;
    }

    @media (min-width: 768px){
        width: 80%;
    }
    @media (min-width: 1200px){
        width: 45%;
        margin: 1em;
    }
`;

const StyledReasonNumber = styled.div`
    position: absolute;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FCFCFC;
    border-radius: 50%;
    font-size: 1rem;

    &.one{
        left: 10px;
    }
    &.two{
        right: 10px;
    }

    @media (min-width: 320px){
        height: 50px;
        width: 50px;
        font-size: 1.5rem;
    }
    @media (min-width: 400px){
        height: 60px;
        width: 60px;
        font-size: 1.7rem;
    }
    @media (min-width: 700px){
        height: 80px;
        width: 80px;
        font-size: 2rem;
    }
`;

const ReasonText = styled.div`
    font-size: .75rem;
    padding: 1em;
    color: #fff;
    width: 75%;

    @media (min-width: 576px){
        font-size: .85rem;
    }
    @media (min-width: 1250px){
        font-size: .9rem;
    }
`;

const whyInfoText = 'Wspólnie staramy się wybrać najlepsze narzędzia tak, aby wspomóc rozwój Twojej firmy. Wiemy jak dzielić się wiedzą i doświadczeniem. Nie jesteś dla nas klientem a partnerem, z którym mamy wspólny cel.'

const reasonContent = [
    {
        class: 'one',
        number: '01',
        text: 'Wszystko na czas - deadline jest dla nas kluczowy, bo wiemy jak istotny jest czas w biznesie'
    },
    {
        class: 'two',
        number: '02',
        text: 'Dbałość o relacje - wiemy, że jeśli Twoja firma się nie rozwija, nie rozwija się też WebyBear'
    },
    {
        class: 'one',
        number: '03',
        text: 'Szybkość reakcji - e-mail nigdy nie pozostaje bez odpowiedzi, a stały kontakt z Tobą jest dla nas najważniejszy'
    },
    {
        class: 'two',
        number: '04',
        text: 'Konkretne rezultaty - przedstawimy Ci konkretną i precyzyjną drogę do realizacji określonego celu'
    },
    {
        class: 'one',
        number: '05',
        text: 'Pewność i jakość obsługi - rekomendacje naszej pracy to dowód naszej skuteczności i kreatywności'
    },
    {
        class: 'two',
        number: '06',
        text: 'Regularne raportowanie - będziesz miał pełen podgląd naszych aktywności'
    },

];


const WhyUs = () => (
    <StyledWrapper id="dlaczegomy">
        {SectionTitle('Dlaczego warto z nami pracować ?', 'grey')}
        <ContentWrapper>
            <WhyInfo>
                {whyInfoText}
            </WhyInfo>
            <ReasonSection>
                {reasonContent.map((reason, idx) => (
                    <StyledReason className ={reason.class} key={idx}>
                        <StyledReasonNumber className ={reason.class}>
                            <p>{reason.number}</p>
                        </StyledReasonNumber>
                        <ReasonText>
                            <p>{reason.text}</p>
                        </ReasonText>
                    </StyledReason>
                ))}
            </ReasonSection>
        </ContentWrapper>
        <div className="custom-shape-divider-bottom-16190223113">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill-3"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill-3"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill-3"></path>
                </svg>
            </div>
            <img src={Traingle} alt="" className="shape shape--five"/>
            <img src={Square} alt="" className="shape shape--seven"/>

    </StyledWrapper>
)

export default WhyUs