import React from 'react';
import styled from 'styled-components'
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import aboutImg from '../../images/networks-1987215_640.jpg'
import { colors } from '../../utils/colors';
// import { StaticImage } from "gatsby-plugin-image"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import faQuoteLeft from "../../images/quote-left-solid.svg"
import Square from "../../images/square.svg"
import Traingle from "../../images/triangle.svg"
import Ex from "../../images/x.svg"

const Picture = styled.div`
    order: 2;
    width: 300px;
    height: 350px;
    background-image: linear-gradient(to top right, rgba(10, 19, 133, .95),  rgba(121, 169, 223,.7)), url(${aboutImg});
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 2px 2px 5px rgb(0,0,0);
    margin-bottom: 5em;  
    
    @media (min-width: 768px) {
        width: 30vw;
        height: 450px;
    }
`;


const SectionWrapper = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.lightWhite};
    z-index: 0;
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 6em 0;
    width: 80%;
    max-width: 1200px;
`;

const TestimonialsText = styled.div`
    width: 100%;
    order: 2;
    line-height: 1.6;
    text-align: center;
    .signature {
        font-weight: bold;
        text-align: right;
        color:  ${colors.mainBlue}
    }
    
    @media (min-width: 768px) {
        width: 50%;
    }
`;


const testimonialsContent = [

    {
        text: '"At Webybear you can trust with in any project, their approach of managing tasks are highly professional. Webybear are working best with set goals and priorities where they can figure out a solution on how to reach these specific goals within a well defined framework.​."',
        author: 'Gabor Bonyhady CMO'
    },
    {
        text: '"From June through January 2021, I worked with Webybear team together closely. They approach problems from different angles and often comes up with creative solutions. They are a skilled problem-solver team and they always know which questions to ask to get to the bottom of things. "',
        author: 'Agnieszka Zarzycka CEO'
    },
];

const sectionShapes = [
    {
        class: 'shape shape--four',
        src: Square
    },
    {
        class: 'shape shape--five',
        src: Traingle
    },
    {
        class: 'shape shape--seven',
        src: Ex
    },
];

const Testimonials = () => (
    <SectionWrapper id="opinie">
        <ContentWrapper>
            <Picture>
                {SectionTitle('Opinie naszych klientów', 'blue')}
            </Picture>
            <TestimonialsText>
                {testimonialsContent.map((testimonial, idx) => (
                    <div key={idx}>
                        {/* <StaticImage src={faQuoteLeft}/> */}
                        {/* <FontAwesomeIcon icon={faQuoteLeft}/> */}
                        <p>{testimonial.text}</p>
                        <p className="signature">{testimonial.author}</p>
                        <br/>
                    </div>
                ))}
            </TestimonialsText>
        </ContentWrapper>
        <div className="custom-shape-divider-bottom-16190223113">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill-3"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill-3"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill-3"></path>
                </svg>
        </div>
        {sectionShapes.map((shape, idx) => (
            <img src={shape.src} className={shape.class} alt="shape" key={idx}/>
        ))}
    </SectionWrapper>
)

export default Testimonials